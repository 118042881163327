<template>
    <div class="createStepTwo">
        <div class="createStepTwo-header">
            <div class="header-btn">
                <el-button class="btn" @click="robotDelete">删除</el-button>
                <el-button class="btn" @click="allUnfold(tableData,true)">全部展开</el-button>
                <el-button class="btn" @click="allUnfold(tableData,false)">全部收起</el-button>
            </div>
            <div class="header-class">
                <span>使用官方数据</span>
                <el-select v-model="classValue" placeholder="请选择" style="margin-left: 7px">
                    <el-option
                            @click.native="selectRobot(item)"
                            v-for="item in classOptions"
                            :key="item.id"
                            :label="item.kf_name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="createStepTwo-table">
            <el-table :data="tableData" ref="tableData"   @selection-change="handleSelectionChange" row-key="rowId" :tree-props="{children: 'robot_data'}" style="margin-top:20px;width: 100%;flex: 1;border: #EEEEEE 1px solid" :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: 500, color: '#14141C', background: '#F3F4FA',height: '60px'}">
                <el-table-column v-if="tableData.length" type="selection" width="60">
                </el-table-column>
                <el-table-column label="机器人名称" prop="robot_name" width="220">
                    <template slot-scope="scope" v-if="scope.row.robot_data">
                        <el-input style="width: 100%; margin-left: 10px;" type="text" v-model="scope.row.robot_name"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="提问问题" prop="question">
                    <template slot-scope="scope" v-if="!scope.row.robot_data">
                        <el-input style="width: 180px;" type="text" v-model="scope.row.question"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="得分回答" prop="key_word">
                    <template slot-scope="scope" v-if="!scope.row.robot_data">
                        <el-input style="width: 180px;" type="text" v-model="scope.row.key_word" placeholder="关键词用'#'分割"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="不得分关键词" prop="no_key_word" width="200">
                    <template slot-scope="scope" v-if="!scope.row.robot_data">
                        <el-input v-model="scope.row.no_key_word" placeholder="否定关键词'#'分割"></el-input>
                    </template>
                </el-table-column>
                <el-table-column  :label="'设置分值(总分'+ totalScore + '分)'" prop="score">
                    <template slot-scope="scope" v-if="!scope.row.robot_data">
                        <el-input :controls="false" placeholder="1~100" @change="watchTotal2(scope.row)" @input="watchTotal(scope.row,scope.$index)" style="width: 130px;" type="text" v-model="scope.row.score">
                            <template slot="append">分</template>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="提问间隔" prop="question_time" width="150">
                    <template slot-scope="scope" v-if="!scope.row.robot_data">
                        <el-input style="width: 130px;" type="text" @input="watchTotalTime" v-model="scope.row.question_time">
                            <template slot="append">秒</template>
                        </el-input>
                    </template>
                </el-table-column>
                <el-table-column label="移动" align="center">
                    <template slot-scope="{row, $index}" v-if="!row.child">
                        <i class="iconfont down" @click="moveSort('first', row)" v-show="$index !== 0" style="cursor: pointer;">&#xe684;</i>
                        <i class="iconfont down" @click="moveSort('up', row)" v-show="$index !== 0" style="cursor: pointer;">&#xe685;</i>
                        <i class="iconfont down" @click="moveSort('end', row)" v-show="row.rowId !== tableData[tableData.length-1].rowId" style="cursor: pointer;" >&#xe686;</i>
                        <i class="iconfont down" @click="moveSort('down', row)" v-show="row.rowId !== tableData[tableData.length-1].rowId" style="cursor: pointer;">&#xe687;</i>
                    </template>
                </el-table-column>
                <el-table-column label="添加问题" align="center">
                    <template slot-scope="scope">
                        <el-button style="color: #1222D8" v-if="!scope.row.child" type="text" @click="issueAdd(scope.row)">添加问题</el-button>
                        <el-button style="color: #FF0102" v-if="scope.row.child" type="text" @click="issueDelete(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="addRobot">
            <el-button class="addRobotBtn" @click="robotAdd">添加新机器人</el-button>
        </div>
        <div class="createStepBottom">
            <el-button class="last-step" @click="toBack">上一步</el-button>
            <el-button class="next-step" @click="toNext">下一步</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "createStepTwo",
        data(){
            return {
                id:'',
                classValue:'',
                classOptions:[],
                tableData:[],
                robotSelect:[],
                stepOneData:{},
                isUnfold:true,
                totalScore:0
            }
        },
        created() {
            // this.getoffData();
            // let data = JSON.parse(this.$route.query.res);
            // console.log({data})
            // if(this.$route.query.editTransmit){
            //     let editData = JSON.parse(this.$route.query.editTransmit);
            //     editData.editRobotList.map(item=>{
            //         let obj = {
            //             'rowId':this.guid(),
            //             'robot_name':item.robot_name,
            //             'robot_data': item.robot_data
            //         }
            //         this.tableData.push(obj)
            //     })
            //     this.id = editData.id;
            // }
            // this.stepOneData = data;
            // this.watchTotal()
            this.onInit();
        },
        activated() {
            this.onInit();
        },
        methods: {
            moveSort(type, item) {
                let index = this.tableData.findIndex(o => o.rowId === item.rowId);
                this.tableData.splice(index, 1);
                switch(type) {
                    case 'first':
                        this.tableData.unshift(item);
                        break;
                    case 'up':
                        this.tableData.splice(index-1, 0, item);
                        break;
                    case 'end':
                        this.tableData.push(item);
                        break;
                    case 'down':
                         this.tableData.splice(index+1, 0, item);
                        break;
                }
            },
            onInit() {
                this.getoffData();
                let data = {};
                if (!!this.$route.query.res) data = JSON.parse(this.$route.query.res);
                if(this.$route.query.editTransmit) {
                    this.tableData =[];
                    let editData = JSON.parse(this.$route.query.editTransmit);
                    this.tableData = editData.editRobotList.map(item=>{
                        return {
                            // 'id':this.guid(),
                            rowId: this.guid(),
                            id: item.id,
                            robot_name: item.robot_name,
                            robot_data: item.robot_data.map(item1 => {
                                return {
                                    child: item1.child,
                                    key_word: item1.key_word,
                                    no_key_word: item1.no_key_word,
                                    question: item1.question,
                                    question_time: item1.question_time,
                                    rowId: item1.rowId,
                                    // id: item1.rowId,
                                    score: item1.score,
                                }
                            })
                        }
                    })
                    this.id = editData.id;
                } else {
                    this.tableData =[];
                    this.classValue = ''
                    this.id = ''
                }
                this.stepOneData = data;
                this.watchTotal()
            },
            // storeChange(index){
            //     console.log(106,index)
            //     return;
            //     if(val){
            //         let dot = val.indexOf(".");
            //         if(dot === 0){
            //             this.$message.warning('输入格式错误，小数点不能位于第一位！');
            //             this.form.price = ''
            //             return
            //         }
            //         let list = val.split('');
            //         let num = 0;
            //         list.map(item=>{
            //             if(item == '.'){
            //                 num++
            //             }
            //         })
            //         if(num > 1){
            //             this.$message.warning('输入格式错误，只能有一个小数点！');
            //             this.form.price = ''
            //             return;
            //         }
            //         this.form.price = Number(val).toFixed(2)
            //     }
            // },
            watchTotal2(row){
                // console.log(133,row)
                for(let i = 0; i < this.tableData.length; i++){
                    for(let j = 0; j < this.tableData[i].robot_data.length; j++){
                        if(this.tableData[i].robot_data[j].rowId === row.rowId){
                            this.tableData[i].robot_data[j].score = this.myFixed(row.score, 1)
                            if(row.score < 0.1){
                                this.tableData[i].robot_data[j].score = 0.1
                            } else if(row.score > 100){
                                this.tableData[i].robot_data[j].score = 100
                            }
                        }
                    }
                }
                this.watchTotal()
            },
            watchTotal(){
                let num = 0;
                for(let i = 0; i < this.tableData.length; i++){
                    for(let j = 0; j < this.tableData[i].robot_data.length; j++){
                        if(this.tableData[i].robot_data[j].score == ''){
                            this.tableData[i].robot_data[j].score = '';
                        }
                        num += Number(this.tableData[i].robot_data[j].score)
                    }
                }
                this.totalScore = this.myFixed(num, 1)

            },
            myFixed(a, b) {//a:数字，b:小数点后有几位数
                return Math.round(a * Math.pow(10, b)) / Math.pow(10, b);
            },
            watchTotalTime(){
                for(let i = 0; i < this.tableData.length; i++){
                    for(let j = 0; j < this.tableData[i].robot_data.length; j++){
                        if(!Number(this.tableData[i].robot_data[j].question_time)){
                            this.tableData[i].robot_data[j].question_time = '';
                        }
                    }
                }
            },
            getoffData(){
                this.$http.axiosGet(this.$api.showKfList+'?type=all', (res)=>{
                    if(res.code === 200){
                        this.classOptions = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            selectRobot(val){
                this.$http.axiosGetBy(this.$api.findByKfDate, {id:val.id}, res=>{
                    if(res.code === 200){
                        this.tableData = res.data.robot_data.map(item => {
                            return {
                                rowId: this.guid(),
                                id: item.id,
                                robot_name: item.robot_name,
                                robot_data: item.robot_data.map(item1 => {
                                    return {
                                        child: item1.child,
                                        key_word: item1.key_word,
                                        no_key_word: item1.no_key_word,
                                        question: item1.question,
                                        question_time: item1.question_time,
                                        // id: item1.rowId,
                                        rowId: item1.rowId,
                                        score: item1.score,
                                    }
                                })
                            }
                        })
                        this.watchTotal()
                        this.robotSelect = []
                    } else {
                        this.$message.warning(res.msg);
                    }
                })
            },
            toBack(){
                let newData = {...JSON.parse(this.$route.query.res), ...{editRobotList: this.tableData}, ...{id: this.id}};
                this.$router.push({
                    path:'/admin/storecustomer/createstepone',
                    query:{
                        data: newData,
                    }
                })
            },
            toNext(){
                if(this.tableData.length<1){
                    this.$message.warning('请添加机器人！')
                    return;
                } else if(this.tableData.length>0){
                    let num = 0;
                    for(let i = 0; i < this.tableData.length; i++){
                        for(let j = 0; j < this.tableData[i].robot_data.length; j++){
                            if(!Number(this.tableData[i].robot_data[j].score)){
                                this.tableData[i].robot_data[j].score = '';
                            }
                            num += Number(this.tableData[i].robot_data[j].score)
                        }
                        if(this.tableData[i].robot_name === ''){
                            this.$message.warning('每项为必填！')
                            return;
                        } else if(this.tableData[i].robot_data.length<1){
                            this.$message.warning('每个机器人需最少添加一个问题！')
                            return;
                        } else if(this.tableData[i].robot_data.length>0){
                            for(let j = 0; j < this.tableData[i].robot_data.length; j++){
                                if(this.tableData[i].robot_data[j].key_word === ''
                                        || this.tableData[i].robot_data[j].question === ''
                                            || this.tableData[i].robot_data[j].question_time === ''
                                                || this.tableData[i].robot_data[j].score === ''
                                                    || Number(this.tableData[i].robot_data[j].no_key_word) === 0){
                                    this.$message.warning('问题每项为必填！')
                                    return;
                                }
                            }
                        }
                    }
                    if(num !== 100) {
                        this.$message.warning('总分值必须等于100！')
                        return;
                    }
                }
                let robotData = JSON.stringify(this.tableData);
                let formData = new FormData();
                formData.append('kf_name', this.stepOneData.name);
                formData.append('category_id', this.stepOneData.industryClass);
                formData.append('category_name', this.stepOneData.category_name);
                formData.append('pv_interval', this.stepOneData.interval);
                formData.append('response_interval', this.stepOneData.responseTime);
                formData.append('remark', this.stepOneData.remark);
                formData.append('forbidden', this.stepOneData.forbidden);
                formData.append('robot_data',robotData);
                formData.append('explain', this.stepOneData.explain);
                if(this.id !== ''){
                    formData.append('id', this.id);
                    this.$http.axiosPost(this.$api.saveKfDate, formData, res=>{
                        if(res.code === 200){
                            this.$router.push({
                                path:'/admin/storecustomer/createstepsucceed'
                            })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                } else {
                    this.$http.axiosPost(this.$api.createKfData, formData, res=>{
                        if(res.code === 200){
                            this.$router.push({
                                path:'/admin/storecustomer/createstepsucceed'
                            })
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            },
            //新增机器人
            robotAdd(){
                let obj = {
                    'rowId':this.guid(),
                    'robot_name':'',
                    'robot_data': []
                }
                this.tableData.push(obj);
            },
            allUnfold(arr, isExpand) {
                arr.forEach(i => {
                    this.$refs.tableData.toggleRowExpansion(i, isExpand)
                    if (i.children) {
                        this.closeUnfold(i.children, isExpand)
                    }
                })
            },
            closeUnfold(arr, isExpand) {
                arr.forEach(i => {
                    this.$refs.tableData.toggleRowExpansion(i, isExpand)
                    if (i.children) {
                        this.closeUnfold(i.children, isExpand)
                    }
                })
            },
            // 添加问题
            issueAdd(row){
                let obj = {
                    'rowId':this.guid(),
                    'question':'',
                    'key_word':'',
                    'no_key_word': '',
                    'score':'',
                    'question_time':'',
                    'child':true
                }
                for(let i = 0; i < this.tableData.length; i++){
                    if(this.tableData[i].rowId === row.rowId){
                        this.tableData[i].robot_data.push(obj)
                    }
                }
                // return
                // if(this.$route.query.editTransmit){
                //     for(let i = 0; i < this.tableData.length; i++){
                //         if(this.tableData[i].id === row.id){
                //             console.log(205,'aaaaa')
                //             this.tableData[i].robot_data.push(obj)
                //         }
                //     }
                // } else{
                //     console.log(206,'aaaaa')
                //     for(let i = 0; i < this.tableData.length; i++){
                //         if(this.tableData[i].rowId === row.rowId){
                //             this.tableData[i].robot_data.push(obj)
                //         }
                //     }
                // }

            },
            // 删除问题
            issueDelete(row){
                for(let i = 0; i < this.tableData.length; i++){
                    for(let j = 0; j< this.tableData[i].robot_data.length; j++){
                        if(this.tableData[i].robot_data[j].rowId === row.rowId){
                            this.tableData[i].robot_data.splice(j,1)
                        }
                    }
                }
                this.watchTotal();
            },
            // 删除选中机器人
            robotDelete(){
                if(this.robotSelect.length<1){
                    this.$message('请选中要删除的数据！')
                } else {
                    for(let i = 0; i < this.robotSelect.length; i++){
                        for(let j = 0; j < this.tableData.length; j++){
                            if(this.robotSelect[i] === this.tableData[j].id){
                                this.tableData.splice(j,1)
                            }
                        }
                            }
                    this.watchTotal()
                }
            },
            // 多选
            handleSelectionChange(val){
                this.robotSelect = val.map(item => item.id)
            },
            // 生成id
            guid(){
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0,v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                })
                // var timestamp = Date.parse(new Date());
                // return timestamp
            },
        }
    }
</script>

<style scoped lang="scss">
    /deep/.expanded .el-table_1_column_1 .cell{
        display: flex;
        align-items: center;
    }
    /deep/.el-table__row--level-1 .el-table-column--selection .cell .el-checkbox{
        display: none;
    }
    /deep/ .el-table .cell {
      position: relative;
    }
    /deep/ .el-table .el-table__expand-icon {
      position: absolute;
      left: -5px;
      top: 8px;
    }
    .createStepTwo{
        .createStepTwo-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn:nth-child(1){
                margin-left: 0;
            }
            .btn{
                width: 100px;
                height: 30px;
                line-height: 1px;
                background: #ffffff;
                border-color: #1222D8;
                color: #1222D8;
                margin-left: 20px;
            }
        }
        .header-class{
            color: #343434;
            font-size: 16px;
        }
        .addRobot{
            padding-top: 50px;
            .addRobotBtn{
                width: 126px;
                height: 30px;
                line-height: 0;
                background: #1222D8;
                border-color: #1222D8;
                color: #ffffff;
            }
        }
        .createStepBottom{
            margin-top: 100px;
            display: flex;
            justify-content: center;
            .last-step{
                width: 100px;
                background: #ffffff;
                color: #1122D8;
                border-color:#1122D8 ;
            }
            .next-step{
                width: 100px;
                background: #1122D8;
                color: #ffffff;
                border-color: #1122D8;
                margin-left: 20px;
            }
        }
    }
</style>